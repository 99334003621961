import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import "./styles.css";
import BackgroundImage from "../../../images/graphics/admin-record-bg.png";

// MUI Imports
import {
  Typography,
  CircularProgress,
  Container,
  List,
  LinearProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  InputBase,
} from "@material-ui/core";
import { Box } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import WestIcon from "@mui/icons-material/West";
import SearchIcon from "@material-ui/icons/Search";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import AddRecordModal from "./AddRecordModal";
import { getSearchRecords } from "../../../actions";
import SingleAddRecord from "./SingleAddRecord";
import Centered from "../../layout/Centered";

// Styles
const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "grid",
    gridTemplateColumns: " repeat(3, minmax(0, 1fr))",
    width: "100%",
  },

  container: {
    maxHeight: "80vh",
    border: "1px solid #6C757D",
    borderRight: 0,
    borderLeft: 0,
    borderTop: 0,
    // borderRadius: "6px",
  },

  heading: {
    fontSize: "46px",
    fontWeight: "500",
    color: "#7D80C6",
    textAlign: "center",
  },
  // ---------------------
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "18px",
    marginTop: "25px",
    marginBottom: "30px",
  },
  search: {
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    border: "1px solid #000",

    width: "380px",
  },
  searchIcon: {
    padding: "10px",

    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  inputInput: {
    // padding: theme.spacing(1, 1, 1, 0),
    width: "100%",
  },

  sticky: {
    position: "fixed",
    top: 0,
    width: "100%",
  },

  // ------------------

  tableHeader: {
    fontFamily: "Bebas Neue",
    textAlign: "center",

    padding: "16px",
    fontSize: "16px",
    fontWeight: "400",
    color: "#383050",
    borderRight: "0px",
    // borderLeft: "0px",
    borderTop: "1px solid #6C757D",
    borderColor: "#6C757D",
    whiteSpace: "nowrap",
  },

  logo: {
    width: "112px",
    marginLeft: "6px",
  },
  backIcon: {
    fontSize: "2.3rem !important",
    color: "#7D80C6 !important",
  },
  primaryBtn: {
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "12px",
    paddingBottom: "12px",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Roboto",
    backgroundColor: "#7D80C6",
    color: "white",
    borderRadius: "4px",
    border: "none",
    // marginTop: "25px",
    // marginBottom: "30px",
    cursor: "pointer",
  },
  outlineBtn: {
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "12px",
    paddingBottom: "12px",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Roboto",
    backgroundColor: "#fff",
    color: "#383050",
    borderRadius: "4px",
    border: "1px solid #000",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ECECEC",
    },
  },
  removeBtnClasses: {
    backgroundColor: "#fff",
    border: 0,
    padding: 0,
    cursor: "pointer",
  },
}));

const DatabaseRecords = (props) => {
  const classes = useStyles();

  const [openAddRecordModal, setOpenAddRecordModal] = useState(false);
  const cancelTokenRef = useRef(); // To hold the cancel token reference

  const [updatedRecordId, setUpdatedRecordId] = useState("");

  const [searchText, setSearchText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { loading, records } = props;

  const [record, setRecord] = useState({
    jobTitle: "",
    familyType: "",
    secondaryFamily: "",
    MBTI_1: "", //introvert/extrovert
    MBTI_2: "", //motivation
    MBTI_3: "", //judging/perceiving
    SII_1: "",
    SII_2: "",
    SII_3: "",
    salary: "",
    stress_level: "",
    min_edu: "",
    description: "",
  });

  const handleConfirmClose = () => {
    setOpenAddRecordModal(!openAddRecordModal);
  };

  const searchTextOnChangeHandler = (event) => {
    const value = event.target.value;

    setSearchText(value);
    if (value === "") {
      searchTextSubmitHandler(true);
    }
  };

  const searchTextSubmitHandler = (getAllRecord = false) => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Canceling previous request.");
    }

    cancelTokenRef.current = axios.CancelToken.source();
    props.getSearchRecords(
      getAllRecord ? "" : searchText,
      cancelTokenRef.current.token
    );
  };

  const columns = [
    { id: "jobTitle", label: "Job Title" },
    { id: "familyType", label: "Family type" },
    {
      id: "secondaryFamily",
      label: "Secondary family",
      // align: "right",
      // format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "SII_1",
      label: "Interest 1",
    },
    {
      id: "SII_2",
      label: "Interest 2",
    },
    {
      id: "SII_3",
      label: "Interest 3",
    },
    {
      id: "MBTI_1",
      label: "introvert/extrovert",
    },
    {
      id: "MBTI_2",
      label: "motivation",
    },
    {
      id: "MBTI_3",
      label: "judging/perceiving",
    },
    {
      id: "salary",
      label: "Salary",
    },
    {
      id: "stress_level",
      label: "stress level",
    },
    {
      id: "min_edu",
      label: "Minimum education",
    },
    {
      id: "description",
      label: "Description",
    },
  ];

  useEffect(() => {
    props.getSearchRecords(searchText);
  }, []);

  // const [isSticky, setIsSticky] = useState(false);
  // const headerRef = useRef(null);

  //   useEffect(() => {
  //     const handleScroll = () => {

  //       const header = headerRef.current;
  //       // const offset = header.getBoundingClientRect().top;
  //       const offset = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop; // Get scroll position

  //       var header2 = document.getElementById("myHeader");
  // var sticky=header.offsetTop;

  //       console.log('in scollr check...');
  //       console.log('offset ',offset);
  //       console.log('sticky ',sticky);

  //       // If the header's top reaches the viewport's top, make it sticky
  //       // if (offset <= 0) {
  //       if(offset>sticky){
  //         setIsSticky(true);
  //       } else {
  //         setIsSticky(false);
  //       }

  //       // if (offset > sticky) {
  //       //   // header.classList.add("sticky");
  //       //   setIsSticky(true);
  //       // } else {
  //       //   // header.classList.remove("sticky");
  //       //   setIsSticky(false);
  //       // }
  //     };

  //     // Attach scroll event listener
  //     window.addEventListener("scroll", handleScroll);

  //     // Clean up the event listener on component unmount
  //     return () => {
  //       window.removeEventListener("scroll", handleScroll);
  //     };

  //   }, []);

  // useEffect(()=>{
  // console.log(isSticky);

  // },[isSticky])
  return (
    <Box
      sx={{
        paddingY: "30px",
        paddingX: "24px",
        backgroundImage: `url(${BackgroundImage})`,
        backgroundColor: "white",
        backgroundSize: "cover",
        objectFit: "cover",
        minHeight: "100vh",
        fontFamily: "Bebas Neue",
      }}
    >
      <div>
        <div className={classes.headerContainer}>
          {/* <img src={Logo} className={classes.logo} alt="Logo" /> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link to="/admin/search-record">
              <WestIcon fontSize="inherit" className={classes.backIcon} />
            </Link>
          </div>
          <div className={classes.heading}>ADD A RECORD</div>
          <div />
        </div>

        <div className={classes.searchContainer}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search by job title"
              // disabled={loading}
              className={classes.inputInput}
              value={searchText}
              onChange={searchTextOnChangeHandler}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchTextSubmitHandler();
                }
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <button
            className={classes.outlineBtn}
            onClick={() => searchTextSubmitHandler(false)}
          >
            Search
          </button>
          <button
            className={classes.primaryBtn}
            onClick={() => setOpenAddRecordModal(true)}
          >
            Add Record
          </button>
        </div>

        {loading && (
          <Centered>
            <CircularProgress style={{ color: "#7D80C6" }} />
          </Centered>
        )}

        {!loading && records?.length === 0 && <div>No records found</div>}

        {records?.length > 0 && !loading && (
          <Paper>
            <TableContainer
              className="no-scrollbar"
              style={{
                maxHeight: "95vh",
              }}
            >
              <Table
                stickyHeader
                className={classes.container}
                aria-label="sticky table"
                style={{
                  position: "relative",
                }}
              >
                <TableHead
                  // ref={headerRef}
                  // className={isSticky ? classes.sticky : ""}
                  style={{
                    // position: "fixed",
                    overflowX: "scroll",
                    top: 0,
                    zIndex: 10,
                    backgroundColor: "white",
                  }}
                >
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        // align={column.align}
                        className={classes.tableHeader}
                        style={{
                          minWidth: "140px",
                          position: "sticky",
                          left: column.id === "jobTitle" ? 0 : "auto",
                          top: 0, // Sticky top for header
                          zIndex: column.id === "jobTitle" ? 3 : 1, // Ensure Name column is on top
                          background: "white",
                          borderLeft:
                            column.id === "jobTitle" ? "1px solid #6C757D" : 0,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    {/* delete header */}
                    <TableCell
                      className={classes.tableHeader}
                      style={{
                        position: "sticky",
                        right: 0,
                        top: 0,
                        zIndex: 3,
                        background: "white",
                        borderLeft: 0,
                        borderRight: "1px solid #6C757D",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records?.map((record, index) => {
                    return (
                      <SingleAddRecord
                        key={index}
                        record={record}
                        setUpdatedRecordId={setUpdatedRecordId}
                        setOpenConfirmModal={setOpenAddRecordModal}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}

        {openAddRecordModal && (
          <AddRecordModal
            handleConfirmClose={handleConfirmClose}
            record={record}
            setUpdatedRecordId={setUpdatedRecordId}
            updatedRecordId={updatedRecordId}
          />
        )}
      </div>
    </Box>
  );
};

const mapDispatchToProps = {
  getSearchRecords,
};

const mapStateToProps = (state) => {
  return {
    records: state.records.records,
    loading: state.records.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatabaseRecords);
