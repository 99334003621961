import {
  SET_RECORDS_LOADING,
  SET_RECORDS,
  SET_RECORD_OPTIONS,
  ADD_RECORDS_SUCCESS,
  SET_FILTER_OPTIONS_LOADING,
  ADD_RECORD_LOADING,
  DELETE_RECORD_SUCCESS,
  UPDATE_RECORD_LOADING,
  UPDATE_RECORD_SUCCESS,
  ADD_UPDATE_RECORD_LOADING,
  CLEAN_ADD_RECORD,
  ADD_RECORD_FAILED,
  DELETE_RECORD_LOADING,
} from "../actions/index";

const initialState = {
  loading: false,
  records: [],
  filters: {},
  successMessage: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_RECORDS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_UPDATE_RECORD_LOADING:
      return {
        ...state,
        addUpdateRecordLoading: true,
      };
    case DELETE_RECORD_LOADING:
      return {
        ...state,
        deleteRecordLoading: true,
      };
    case SET_FILTER_OPTIONS_LOADING:
      return {
        ...state,
        optionsLoading: true,
      };
    case ADD_RECORDS_SUCCESS:
      return {
        ...state,
        addUpdateRecordLoading: false,
        successMessage: action.data.message,

        // Add the new record to the list and sort the records alphabetically by jobTitle
        records: [...state.records, action?.data?.record].sort((a, b) =>
          a.jobTitle.localeCompare(b.jobTitle)
        ),
      };
    case ADD_RECORD_FAILED:
      return {
        ...state,
        addUpdateRecordLoading: false,
        successMessage: action.data.message,
      };
    case CLEAN_ADD_RECORD:
      return {
        ...state,
        addUpdateRecordLoading: false,
        successMessage: "",
      };
    case DELETE_RECORD_SUCCESS:

      return {
        ...state,
        // Filter out the deleted record by its ID
        deleteRecordLoading: false,
        // successMessage: action.data.response.message,
        records: state.records.filter((record) => record._id !== action.data.id),
      };

    case UPDATE_RECORD_SUCCESS:
      return {
        ...state,
        addUpdateRecordLoading: false,
        successMessage: action.data.message,
        // Update the record with the new data
        records: state.records.map((record) =>
          record._id === action.data?.record?._id ? action.data.record : record
        ),
      };

    case SET_RECORDS:
      return {
        ...state,
        loading: false,
        records: action.data.records,
      };

    case SET_RECORD_OPTIONS:
      return {
        ...state,
        loading: false,
        optionsLoading: false,
        filters: action.data.filterOptions,
      };

    default:
      return state;
  }
}
