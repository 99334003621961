import React from "react";

// MUI Imports
import { makeStyles } from "@material-ui/core/styles";

import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import { deleteRecord } from "../../../../actions";
import { connect } from "react-redux";
import { Alert } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  modalMain: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    backgroundColor: "#00000099",
    /* 80% opacity */
  },

  modalTrans: {
    position: "fixed",
    inset: 0,
    zIndex: -10,
  },

  labelText: {
    fontSize: "14px",
    fontWeight: "500",
    color: "black",
    fontFamily: "Roboto",
    paddingTop: "20px",
    paddingBottom: "4px",
    textAlign: "center",
    width: "90%",
  },

  doneBtn: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "6px",
    paddingBottom: "6px",
    fontWeight: " 400",
    fontSize: "16px",
    fontFamily: "Roboto",
    textTransform: "capitalize",

    backgroundColor: "#7D80C6",
    border: "1px solid #7D80C6",
    color: "white",
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#6B6EB5",
    },
  },
  outlineBtn: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "6px",
    paddingBottom: "6px",
    fontWeight: "400",
    fontSize: "16px",
    fontFamily: "Roboto",
    textTransform: "capitalize",

    backgroundColor: "#fff",
    color: "#383050",
    borderRadius: "4px",
    border: "1px solid #000",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ECECEC",
    },
  },
  dialogPaper: {
    borderRadius: "10px",
    backgroundColor: "white",
  },
}));

const ConfirmDeleteModal = ({ onClose, record, ...props }) => {
  const classes = useStyles();

  const { deleteRecordLoading } = props;

  const DeleteRecordHandler = () => {
  
    props.deleteRecord(record._id, onClose);
  };

  return (
    <div className={classes.modalMain}>
      <div className={classes.modalTrans} onClick={() => onClose(false)}></div>

      <div className={classes.dialogPaper}>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography className={classes.labelText}>
            Are you sure you want to delete this record?
          </Typography>
          {/* <Typography className={classes.labelText}>{record._id}</Typography> */}
          <br />
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "center",
            gap: "14px",
            marginBottom: "24px",
          }}
        >
          <Button onClick={() => onClose(false)} className={classes.outlineBtn}>
            No
          </Button>
          <Button onClick={DeleteRecordHandler} className={classes.doneBtn}>
            {deleteRecordLoading ? (
              <CircularProgress style={{ color: "white" }} size={27} />
            ) : (
              "Yes"
            )}
          </Button>
        </DialogActions>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  deleteRecord,
};

const mapStateToProps = (state) => {
  return {
    deleteRecordLoading: state.records.deleteRecordLoading,
    // filters: state.records.filters,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteModal);
